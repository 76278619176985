body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

@font-face {
  font-family: 'KoHo';
  font-style: normal;
  font-weight: 400;
  src: url(../src/Assets/Fonts/KoHo-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'KoHo';
  font-style: normal;
  font-weight: 500;
  src: url(../src/Assets/Fonts/KoHo-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'KoHo';
  font-style: normal;
  font-weight: 700;
  src: url(../src/Assets/Fonts/KoHo-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  src: url(../src/Assets/Fonts/Pretendard-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  src: url(../src/Assets/Fonts/Pretendard-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Luckiest Guy';
  font-style: normal;
  font-weight: 400;
  src: url(../src/Assets/Fonts/LuckiestGuy-Regular.ttf) format('opentype');
}
